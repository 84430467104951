import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import { api } from '../../services';

const PlanRouter = ({ component: Component, location, ...rest }) => {
  const isLogged = useSelector(({ login }) => login.isLogged);
  const currentToken = useSelector(({ login }) => login.token);

  const [loading, setLoading] = useState(true);
  const [enabledPlan, setEnabledPlan] = useState(null);
  const [ok, setOk] = useState(false);

  const { statusPlan } = api();

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    const fetchPage = async ignore => {
      try {
        const dataStatusPlan = await statusPlan(currentToken);
        setEnabledPlan(dataStatusPlan);
        if (isLogged && dataStatusPlan && dataStatusPlan.route) {
          navigate(dataStatusPlan.route, { state: { previousPath: '/etapa' } });
          return null;
        } else {
          setOk(true);
        }
      } catch (e) {
        // navigate('/404', { state: { previousPath: '/menu' } });
        navigate('/login', { state: { previousPath: '/logout' } });
        console.log(e);
      } finally {
        setLoading(false);
        setOk(true);
      }
      return () => {
        ignore = true;
      };
    };
    if (!ignore && !isLogged && location.pathname !== '/planes') {
      navigate('/login', { state: { previousPath: '/menu' } });
    } else {
      fetchPage(ignore);
    }

    return () => {
      ignore = true;
      // setOk(true);
    };
  }, []);

  return loading ? '' : ok && <Component enabledPlan={enabledPlan} {...rest} />;
};

export default PlanRouter;
