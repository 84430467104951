import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import { api } from '../../services';

const PremiumRouter = ({ component: Component, location, ...rest }) => {
  const isLogged = useSelector(({ login }) => login.isLogged);
  const currentToken = useSelector(({ login }) => login.token);

  const [loading, setLoading] = useState(true);
  const [ok, setOk] = useState(false);

  const { statusPayPlan } = api();

  useEffect(() => {
    console.debug('rest', rest);
    let ignore = false;
    setLoading(true);
    const fetchData = async ignore => {
      try {
        if (!ignore && location.pathname !== '/planes') {
          const { route } = await statusPayPlan(currentToken);
          console.debug('route', route, location);

          if (route) {
            console.debug('redir');
            navigate(route, { state: { previousPath: '/menu' } });
            setOk(false);
            return null;
          } else {
            setOk(true);
          }
        } else {
          setOk(true);
        }
      } catch (e) {
        // navigate('/404', { state: { previousPath: '/menu' } });
        navigate('/login', { state: { previousPath: '/logout' } });
      } finally {
        // setOk(true);
        setLoading(false);
      }
    };
    if (!isLogged) {
      navigate('/login', { state: { previousPath: '/menu' } });
    } else {
      fetchData(ignore);
    }
    return () => {
      ignore = true;
    };
  }, []);

  return loading ? '' : ok && <Component {...rest} />;
};

export default PremiumRouter;
