import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

const LoginRouter = ({ component: Component, location, ...rest }) => {
  const isLogged = useSelector(({ login }) => login.isLogged);

  const [loading, setLoading] = useState(true);
  const [ok, setOk] = useState(false);

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    if (!ignore && !isLogged && location.pathname !== '/planes') {
      navigate('/login', { state: { previousPath: '/menu' } });
    } else {
      setLoading(false);
      setOk(true);
    }

    return () => {
      ignore = true;
      setOk(true);
    };
  }, []);

  return loading ? '' : ok && <Component {...rest} />;
};

export default LoginRouter;
