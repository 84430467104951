import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from '@reach/router';

const StepRedirect = () => {
  const stepStore = useSelector(store => store.step);
  // steps unlocks
  const unlockedSteps = stepStore.unlockedSteps.map(step => step.stepId);
  // tasks unlocks
  const unlockedTasks = stepStore.unlockedSteps.map(step => step.tasks).flat();
  const lastStep = Math.max(...unlockedSteps);
  const lastTask = Math.max(...unlockedTasks);

  return <Redirect noThrow to={`/etapa/${lastStep}/tarea/${lastTask}`} />;
};

export default StepRedirect;
