import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';

import { PremiumRoute } from '../components/router';

import { MiPlanLayout } from '../layouts';
import { api } from '../services';

const MiPlan = () => {
  const [data, setData] = useState({});
  const [labels, setLabels] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);

  const { fetchMyPlan } = api();

  useEffect(() => {
    let ignore = false;
    setLoading(true);
    const fetchData = async ignore => {
      try {
        let response = null;
        response = await fetchMyPlan(currentToken, currentLang);

        if (!ignore) {
          setData(response.plan);
          setLabels(response.labels);
          console.debug(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Router>
      <PremiumRoute
        path="/mi-plan/"
        component={MiPlanLayout}
        data={data}
        labels={labels}
        loading={loading}
        currentLang={currentLang}
        error={error}
      />
    </Router>
  );
};

export default MiPlan;
