import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from '@reach/router';

const StepSelectorRedirect = ({ stepId }) => {
  const stepStore = useSelector(store => store.step);
  // find if stepId is unlocked
  const id = parseInt(stepId, 10);
  const step = stepStore.unlockedSteps.find(step => step.stepId === id);
  if (step) {
    const tasks = step.tasks;
    const lastTask = Math.max(...tasks);
    const firstTask = Math.min(...tasks);
    return <Redirect noThrow to={`/etapa/${stepId}/tarea/${firstTask}`} />;
  } else {
    // steps unlocks
    const unlockedSteps = stepStore.unlockedSteps.map(step => step.stepId);
    // tasks unlocks
    const unlockedTasks = stepStore.unlockedSteps
      .map(step => step.tasks)
      .flat();
    const lastStep = Math.max(...unlockedSteps);
    const lastTask = Math.max(...unlockedTasks);
    const firstTask = Math.min(...unlockedTasks);

    return <Redirect noThrow to={`/etapa/${lastStep}/tarea/${firstTask}`} />;
  }
};

export default StepSelectorRedirect;
